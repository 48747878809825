import React from "react"
import { StyledLinkButton } from "./styled"

const LinkButton = ({ children, to, styles }) => {
  return (
    <StyledLinkButton
      to={to}
      {...styles}
    >
      {children}
    </StyledLinkButton>
  )
}

export default LinkButton
