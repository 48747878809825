import styled, { css } from "styled-components";
import { Link } from "gatsby";

export const StyledLinkButton = styled(Link)`
    text-decoration: none;
    display: inline-block;
    color: rgb(255, 255, 255);
    height: 3.5rem !important;
    width: 8rem;
    padding: 0px 2rem;
    font-size: 1.125rem !important;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-weight: 700;
    font-family: Nunito Sans;
    line-height: 1.5rem;
    border: .0625rem solid var(--link-button-main-border-color);

    ${({ normal }) => css`${normal}`}

    &:hover {
        border-color: var(--main-gold);
        ${({ hover }) => css`${hover}`}
    }
`;